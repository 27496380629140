:root {
  --orange: #ff8623;
  --black: #202427;
  --greySoft: #c8c9ca;
  --greyHard: #737473;
  --shadeHard: #e0e0e0;
  --shadeSoft: #f0f0f0;
  --red: #f44336;
  --green: #00e676;
}
.gridTitle {
  margin-top: 30px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  place-items: center;
  font-weight: 500;
}

/*take from column 2 to 9*/
.itemTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 3 / 10;
  background-color: var(--black);
  color: var(--shadeSoft);
  width: 100%;
  height: 2.2rem;
  padding: 0.7rem;
  border-radius: 5px;
  text-align: center;
  font-size: 0.8rem;
}

.modulosTitle{
  grid-column: 1 / 2;
  background-color: var(--orange);
  color: var(--shadeSoft);
  width: 100%;
  height: 2.2rem;
  padding: 0.7rem;
  border-radius: 5px;
  text-align: center;
  font-size: 0.8rem;
}

@media (max-width: 1024px) {
  .gridTitle {
    display: none;
    grid-template-columns: repeat(7, 1fr);
    font-size: 0.7rem;
  }
  .itemTitle{
    grid-column: 3 / 8;
    font-size: 0.7rem;
  }
  .modulosTitle{
    grid-column: 1 / 3;
    font-size: 0.7rem;
  }

}

@media (min-width: 1025px) and (max-width: 1440px){
  .gridTitle {

    font-size: 0.7rem;
  }
  .itemTitle{

    font-size: 0.7rem;
  }
  .modulosTitle{

    font-size: 0.7rem;
  }
}