:root {
  --orange: #ff8623;
  --black: #202427;
  --greySoft: #c8c9ca;
  --greyHard: #737473;
  --shadeHard: #e0e0e0;
  --shadeSoft: #f0f0f0;
  --red: #f44336;
  --green: #00e676;
  --boxTotal: #e3e5e8;
}

.box{
  display: flex;
  flex-direction: row-reverse;
}

.totalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  height: 100%;
  padding: 1rem;
  background-color: var(--boxTotal);
  border-radius: 5px;
  margin: 10px 0 10px 0;
}
.price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: 500;
}

.advice{
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--greyHard);
  margin-top: 2rem;
}

@media (max-width: 1024px) {
  .totalContainer {
    width: 100%;
  }
}