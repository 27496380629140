:root {
  --orange: #ff8623;
  --black: #202427;
  --greySoft: #c8c9ca;
  --greyHard: #737473;
  --shadeHard: #e0e0e0;
  --shadeSoft: #f0f0f0;
  --red: #f44336;
  --green: #00e676;
}

.gridImage{
  display: grid;
  place-items: center;
  padding: 1rem;
}

.title{
  font-size: 1rem;
  color: var(--black);
  margin-top: 0.5rem;
  display: grid;
  place-items: center;
  border-top: 1px solid var(--shadeHard);
  padding: 0.2rem;
}

.title h1{
  font-size: 1.5rem;
  font-weight: 500;

}

.linkContainer{
  display: grid;
  place-items: center;
  margin: 1rem 0 3rem 0;
}

.linkBtn{
  background-color: var(--orange);
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
  width: fit-content;
}

.linkBtn a {
  text-decoration: none;
  color: white;
  font-size: 0.8rem;
}