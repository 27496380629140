:root {
	--orange: #ff8623;
	--black: #202427;
	--greySoft: #c8c9ca;
	--greyHard: #737473;
	--shadeHard: #e0e0e0;
	--shadeSoft: #f0f0f0;
	--red: #f44336;
	--green: #00e676;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

span {
	display: none;
}
.component {
	margin-top: 0.4rem;
}

.module-box {
	/*background-color: var(--orange);*/
	color: var(--black);
	padding: 0.5rem;
	/* border-radius: 0.5rem; */
	border-radius: 5px 0 0 5px;
	border-bottom: 1px solid var(--shadeHard);
	width: 100%;
	display: grid;
	place-items: center;
	font-size: 0.8rem;
	font-weight: 500;
	/* font-size: 1rem; */
	/* width: 150px; */
	/*height: 2.2rem;*/
	height: 40px;
}

.total-box {
	background-color: var(--greyHard);

	color: white;

	padding: 0.5rem;
	border-radius: 0 5px 5px 0;
	font-weight: 500;
	font-size: 0.8rem;
	/*height: 2.2rem;*/
	/*width: 125px;*/
	height: 40px;
	width: 100%;
	display: grid;
	place-items: center;
}

.grid-group {
	display: grid;
	grid-template-columns: repeat(9, 1fr);
	place-items: center;
	font-weight: 500;
	/*grid-gap: 2px;*/
}

/*.grid-group:nth-child(3) {*/
/*	background-color: var(--greyHard);*/
/*	border-radius: 5px;*/
/*}*/

.grid-group > div:nth-child(2) {
	width: 100%;
	height: 100%;
	background-color: var(--greySoft);
	color: var(--black);
}

.grid-group > div:nth-child(3) {
	width: 100%;
	height: 100%;
	background-color: var(--greyHard);
	color: var(--shadeSoft);
}

.grid-group > div:nth-child(4) {
	width: 100%;
	height: 100%;
	background-color: var(--greySoft);
	color: var(--black);
}

.grid-group > div:nth-child(5) {
	width: 100%;
	height: 100%;
	background-color: var(--greyHard);
	color: var(--shadeSoft);
}

.grid-group > div:nth-child(6) {
	width: 100%;
	height: 100%;
	background-color: var(--greySoft);
	color: var(--black);
}

.grid-group > div:nth-child(7) {
	width: 100%;
	height: 100%;
	background-color: var(--greyHard);
	color: var(--shadeSoft);
}

.radio-box {
	width: 100%;
	/* background-color: #f5f5f5; */
	/* border-radius: 0.5rem; */
}

.radio-label {
	width: 100%;
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
	place-items: center;
	/* display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center; */
	/* cursor: pointer; */
	padding: 0.5rem;
	/*height: 2.2rem;*/
	height: 40px;
	font-size: 0.7rem;

	/* background-color: #f5f5f5; */
	/* border-radius: 0.5rem; */

	/* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
	transition: all 0.2s ease-in-out;
}

.radio-label span {
	margin: 0;
}

/*.radio-label hover  */
/*.radio-label:hover {*/
/*	background-color: var(--black);*/
/*	color: white;*/
/*}*/

/*accent color only for first input radio*/
.grid-group > div:nth-child(2) .radio-label {
	/* accent-color: var(--red); */
	accent-color: var(--black);
}

/*accent color for the rest of input radios*/
.grid-group > div:nth-child(3) .radio-label,
.grid-group > div:nth-child(4) .radio-label,
.grid-group > div:nth-child(5) .radio-label,
.grid-group > div:nth-child(6) .radio-label,
.grid-group > div:nth-child(7) .radio-label {
	/* accent-color: var(--green); */
	accent-color: var(--orange);
}

input {
	/*accent-color: var(--orange);*/
	/* radio color */
}

.radio-label input {
	height: 1rem;
	width: 1rem;
	margin-right: 0.4rem;
	/* accent-color: var(--black); */
	/* accent-color: var(--orange); */
	/* ring color radio button */
	margin-top: inherit;
	/* cursor: pointer; */
	/* radio custom color */
}

/* Selecting the radio-label that is a sibling of the input that is checked. */
/* .radio-box input:checked ~ .radio-label {
	background-color: var(--orange);
	color: white;
} */
/* .radio-label:has(input:checked) {
	background-color: var(--black);
	color: white;
	transition: all 0.3s ease-in-out;
} */

.select-box {
	width: 100%;
	background-color: var(--shadeSoft);
	/* border-radius: 0.5rem; */
	/*height: 2.2rem;*/
	height: 40px;
}

.select-box select {
	width: 100%;
	padding: 0.5rem;
	font-size: 0.7rem;
	/*height: 2.2rem;*/
	height: 40px;
	background-color: var(--greySoft);
	font-weight: 500;
	color: var(--black);
	/* background-color: #f5f5f5; */
	/* border-radius: 0.5rem; */
	border: none;
	/* cursor: pointer; */
	/* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
}

.select-box select:focus {
	outline-color: var(--orange);
	outline-width: 2px;
}

.select-box select:hover {
	background-color: var(--black);
	color: white;
}

/* media query mobile */
@media (max-width: 1024px) {
	.module-box {
		font-size: 0.8rem;
		width: 100%;
		height: 100%;
		background-color: var(--black);
		color: white;
		border-radius: 0;
	}
	span {
		display: block;
	}
	.grid-group {
		grid-template-columns: repeat(1, 1fr);
		gap: 0.5rem;
		margin-bottom: 3rem;
	}

	.radio-label {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		align-items: center;
	}

	.total-box {
		width: 100%;
		/*width: 125px;*/
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		border-radius: 0;
	}
}

/* media query tablet */
@media (min-width: 1025px) and (max-width: 1440px) {
	/*.grid-group {*/
	/*	grid-template-columns: repeat(9, 130px);*/
	/*}*/

	/*.module-box {*/
	/*	font-size: 0.7rem;*/
	/*	!*width: 100%;*!*/
	/*	display: grid;*/
	/*	place-items: center;*/
	/*	width: 130px;*/
	/*}*/

	/*.radio-label {*/
	/*	display: flex;*/
	/*	flex-direction: row-reverse;*/
	/*	justify-content: flex-end;*/
	/*	align-items: center;*/
	/*	font-size: 0.7rem;*/
	/*}*/

	/*.radio-label input {*/
	/*	height: 100%;*/
	/*	width: 0.6rem;*/
	/*	margin-right: 0.3rem;*/
	/*}*/

	/*.select-box select {*/
	/*	font-size: 0.7rem;*/
	/*	!*height: 2rem;*!*/
	/*}*/

	/*.total-box {*/
	/*	font-size: 0.7rem;*/
	/*	!*height: 2rem;*!*/
	/*	width: 125px;*/
	/*}*/
}

/* media query desktop */
