.btn-container {
	/* display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center; */
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	grid-gap: 0.5rem;
	place-items: center;
	margin: 0 auto;
	width: 80%;
	height: 100%;
	padding: 50px 0 50px 0;
}

.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 250px;
	height: 35px;
	padding: 0.5rem;
	border-radius: 0.5rem;
	cursor: pointer;
	border: none;
	/* soft shadow minimal */
	box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.2);
	transition: all 0.3s ease-in-out;
}

.btn:hover {
	/* animation */
	transform: translateY(-0.2rem);
	transform: scale(1.05);
	background-color: var(--orange);
}

.btn-active {
	background-color: var(--orange);
	color: white;
	/* soft shadow var(--orange */
	box-shadow: 0 0.5rem 1rem rgba(255, 165, 0, 0.2);
}

.btn-inactive {
	background-color: var(--black);
	color: var(--shadeSoft);
	/* soft shadow white */
	box-shadow: 0 0.1rem 1rem rgba(255, 255, 255, 0.2);
}

/* media query 1024px and below */
@media (max-width: 1024px) {
	.btn-container {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(auto, 1fr);
		grid-gap: 0.5rem;
		place-items: center;
	}
}
