:root {
  --orange: #ff8623;
  --black: #202427;
  --greySoft: #c8c9ca;
  --greyHard: #737473;
  --shadeHard: #e0e0e0;
  --shadeSoft: #f0f0f0;
  --red: #f44336;
  --green: #00e676;
}

.gridGroup {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  place-items: center;
  font-weight: 500;
  /*grid-gap: 2px;*/
  font-size: 0.8rem;
  height: 40px;
}

/*background color only the first div*/
.gridGroup > div:nth-child(1) {
  background-color: var(--orange);
  color: var(--shadeSoft);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: 5px 0 0 5px;
}

.gridGroup > div:nth-child(2) {
  background-color: var(--greySoft);
  color: var(--black);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.gridGroup > div:nth-child(3) {
  background-color: var(--greyHard);
  color: var(--shadeSoft);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.gridGroup > div:nth-child(4) {
  background-color: var(--greySoft);
  color: var(--black);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.gridGroup > div:nth-child(5) {
  background-color: var(--greyHard);
  color: var(--shadeSoft);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.gridGroup > div:nth-child(6) {
  background-color: var(--greySoft);
  color: var(--black);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.gridGroup > div:nth-child(7) {
  background-color: var(--greyHard);
  color: var(--shadeSoft);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.gridGroup > div:nth-child(8) {
  background-color: var(--greySoft);
  color: var(--black);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.gridGroup > div:nth-child(9) {
  background-color: var(--greyHard);
  color: var(--shadeSoft);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: 0 5px 5px 0;
}

/*media query max width 1024*/
@media screen and (max-width: 1024px){
  .component {
    display: none;
  }
}