:root {
	--orange: #ff8623;
	--black: #202427;
	--greySoft: #c8c9ca;
	--greyHard: #737473;
	--shadeHard: #e0e0e0;
	--shadeSoft: #f0f0f0;
	--red: #f44336;
	--green: #00e676;
}

.navbarLink {
	color: #212121;
	text-decoration: none;
	height: 100%;
}

.navbarGrid {
	display: grid;
	grid-template-columns: 1fr 1fr ;
	width: 90%;
	margin: 0 auto;
	place-items: center;
	border-bottom: 2px solid var(--shadeHard);
	height: 70px;
}

.navGirdItem {
	height: 100%;
}
